import * as React from 'react';
import ProductHeroLayout from './ProductHeroLayout';

const backgroundImage = './wallpaper.webp';

export default function ProductHero() {
  return (
    <ProductHeroLayout bgImage={backgroundImage}>
      <h1 style={{
        marginTop: '64px',
        marginBottom: '32px',
        color: 'inherit',
        textAlign: 'center',
        fontSize: '3.50rem',
        fontFamily: 'Playfair Display',
        fontWeight: 700,
        fontStyle: "bold"
      }}>
        SEJA BEM-VINDA(O)(E)!
      </h1>
      <p style={{
        color: 'inherit',
        textAlign: 'center',
        fontSize: '1.2rem',
        marginBottom: '16px',
        marginTop: '32px',
        fontFamily: 'Open Sans',
        fontWeight: 300,
        margin: "0px 0px 16px",
      }}>
        Me chamo Raissa Vicentini, sou psicóloga clínica e minha área de estudo é a saúde mental de mulheres e da comunidade LGBTQIA+. 
        <br />Atendo exclusivamente online, oferecendo suporte para quem busca acolhimento e compreensão, em qualquer lugar do Brasil.
      </p>
    </ProductHeroLayout>
  );
}
